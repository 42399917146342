<template>
  <layout>
    <v-card>
        <v-card-title>
            <v-icon
                large
                left
            >
                mdi-road
            </v-icon>
            <span class="title font-weight-light">บันทึกข้อมูลบริหารบทความ</span>
        </v-card-title>
    </v-card>
    <Confirm ref="confirm"></Confirm>
    <v-card class="my-3">
        <v-card-title>
            <span class="subtitle-1">รายละเอียด</span>
        </v-card-title>
        <v-divider class="mx-4 mb-5" />
        <ValidationObserver ref="form">
            <v-form
                ref="form"
                lazy-validation
            >
                <v-container class="body-2">
                    <v-row>
                        <v-col cols="3">
                            <v-subheader>ชื่อบทความ</v-subheader>
                        </v-col>
                        <v-col cols="5">
                            <validation-provider
                                v-slot="{ errors }"
                                name="articleName"
                                rules="required"
                            >
                                <v-text-field
                                    v-model="articleName"
                                    outlined
                                    dense
                                    :error-messages="errors"
                                    required
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">
                            <v-subheader>เจ้าของบทความ</v-subheader>
                        </v-col>
                        <v-col cols="5">
                            <validation-provider
                                v-slot="{ errors }"
                                name="articleOwner"
                                rules="required"
                            >
                            <v-text-field
                                outlined
                                dense
                                v-model="articleOwner"
                                :error-messages="errors"
                                required
                            ></v-text-field>
                            </validation-provider>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">
                            <v-subheader>ประเภท</v-subheader>
                        </v-col>
                        <v-col cols="5">
                            <validation-provider
                                v-slot="{ errors }"
                                name="articleType"
                                rules="required"
                            >
                                <v-select
                                    :items="articleTypeItems"
                                    v-model="articleType"
                                    item-text="articleTypeName"
                                    item-value="articleTypeName"
                                    dense
                                    outlined
                                    :error-messages="errors"
                                ></v-select>
                            </validation-provider>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">
                            <v-subheader>แหล่งที่ตีพิมพ์</v-subheader>
                        </v-col>
                        <v-col cols="5">
                            <validation-provider
                                v-slot="{ errors }"
                                name="published"
                                rules="required"
                            >
                                <v-text-field
                                    v-model="published"
                                    outlined
                                    dense
                                    :error-messages="errors"
                                    required
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">
                            <v-subheader>ปีที่ตีพิมพ์</v-subheader>
                        </v-col>
                        <v-col cols="5">
                            <validation-provider
                                v-slot="{ errors }"
                                name="publishedYear"
                                rules="required"
                            >
                                <v-text-field
                                    v-model="publishedYear"
                                    outlined
                                    dense
                                    :error-messages="errors"
                                    required
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">
                            <v-subheader>ลิ้งค์ไฟล์บทความ</v-subheader>
                        </v-col>
                        <v-col cols="5">
                            <v-text-field
                                v-model="linkArticleFile"
                                outlined
                                dense
                                required
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    
                    <v-divider class="mx-4 mb-5" />
                    <v-row>
                        <v-col>
                            <v-btn depressed color="success" @click="save()">
                                บันทึก
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </ValidationObserver>
    </v-card>
  </layout>
</template>
<script>
import Api from "@/api/Api";

export default {
    data: () => ({
        articleName: "",
        articleOwner: "",
        articleType: null,
        published: "",
        publishedYear: "",
        linkArticleFile: "",
        articleTypeItems: [
            "บทความวิจัย",
            "บทความวิชาการ"
        ],
        formChkErrors: false,
    }),
    // async mounted() {
    //   this.$store.commit('setLoading', true)
    //   await this.getUserGroup()
    //   await this.getSchool()
    //   this.$store.commit('setLoading', false)
    // },
    methods: {
        
        async save() {
            this.$store.commit('setLoading', true)
            const req = {
                article_name: this.articleName,
                article_owner: this.articleOwner,
                article_type: this.articleType,
                published: this.published,
                published_year: this.publishedYear,
                link_article: this.linkArticleFile
            }

            this.formChkErrors = false
            this.formChkErrors = this.$refs.form.validate()

            if(this.formChkErrors){
                let res = (await Api().post("article", req)).data;

                // console.log('data', res)

                if (res.status == 'success') {
                    this.$store.commit('setLoading', false)
                    await this.$refs.confirm.open('ข้อความ', 'บันทึกข้อมูลเรียบร้อย', { color: 'primary', noconfirm: true })
                    this.$router.push('/article')
                }else{
                    this.$store.commit('setLoading', false)
                }
            }else{
                this.$store.commit('setLoading', false)
            }
        }
    }
};
</script>